/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  useUserIPGrabber,
} from '@cleverrealestate/clever-helpers';
import AuthorPage from '@cleverrealestate/clever-components-v2/dist/components/AuthorPage';
import SEO from '../components/Seo';
import Layout from '../components/layout';

const AuthorBio = ({
  pageContext,
}) => {
  useUserIPGrabber();
  const {
    pathname,
    meta_title,
    author,
    meta_description,
    breadcrumbs,
  } = pageContext;

  return (
    <Layout
      colorTransitionStart={-1}
      shadowTransitionStart={50}
      colorTransitionLength={1}
      shadowTransitionLength={125}
      isWhite
    >
      <SEO
        pathname={pathname}
        title={meta_title}
        description={meta_description}
        breadcrumbs={breadcrumbs}
      />
      <AuthorPage
        author={author}
        breadcrumbs={breadcrumbs}
      />
    </Layout>
  );
};

AuthorBio.propTypes = {
  pageContext: PropTypes.any,
};

AuthorBio.defaultProps = {
  pageContext: {},
};

export default AuthorBio;
